html {
  color: rgb(34, 36, 38);
  text-rendering: optimizelegibility;
}

@screen lg {
  html {
    font-size: 20px;
  }
}
