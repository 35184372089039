@import 'tailwindcss/base';
@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

@import './base';
@import './buttons';
@import './forms';
@import './code';
@import './hero';

.layout {
  @apply mx-auto w-full px-4;
}

@screen sm {
  .layout {
    @apply px-6;
  }
}
@screen md {
  .layout {
    @apply w-3/4;
    max-width: 890px;
  }
}

h1,
h2,
h3 {
  @apply font-bold leading-snug text-gray-900;
}
h1 {
  @apply text-2xl;
}
h2 {
  @apply text-xl;
}
h3 {
  @apply text-lg;
}
h4, h5 {
  @apply font-bold;
}

@screen sm {
  h1 {
    @apply text-3xl;
  }
  h2 {
    @apply text-2xl;
  }
  h3 {
    @apply text-xl;
  }
}

main a,
footer a {
  color: #0366d6;
}
main header a {
  @apply text-gray-800;
}
main a:hover,
footer a:hover {
  @apply underline;
}

hr {
  @apply mb-6;
}

th, td {
  text-align: left;
  padding-right: 1em;
}

.bg-silver {
  background-color: #f8f8ff;
}

.index-post {
  @apply mb-8;
}

.index-title {
  @apply font-bold mb-2 text-xl;
}

.index-alt-title {
  @apply text-gray-700;
}

.page-header {
  @apply mb-8 pb-8 border-b border-gray-400;
}
.post a:hover {
  @apply underline;
}
.post p,
.index-post p,
.post blockquote {
  @apply mb-6;
}
.post .title {
  @apply mb-4 flex items-center;
}
.post .title .anchor {
  @apply hidden;
}

@screen lg {
  .post .title {
    @apply -ml-4 mt-12;
  }

  .post .title-h4,
  .post .title-h5 {
    @apply mt-4;
  }

  .post .title .anchor {
    @apply block mr-1;

    visibility: hidden;
  }
  .post .title:hover > .anchor {
    visibility: visible;
  }
}

.post blockquote {
  @apply italic text-gray-700 p-4;

  background-color: aliceblue;
}

@screen md {
  .post blockquote {
    @apply rounded -mx-6 p-6;
  }
}

@screen lg {
  .post blockquote {
    @apply text-base rounded-lg -mx-8 p-8;
  }
}

.post blockquote p {
  @apply border-l border-blue-400 pl-6 pr-4 pt-2 pb-2;

  margin: 0;
}

.post ul,
.post ol {
  @apply mb-8;
}

.post ul li ul,
.post ol li ol {
  @apply mb-2;
}

.post ul li {
  list-style-type: disc;

  @apply ml-4 text-gray-900;
}

.post ol li {
  list-style-type: decimal;

  @apply ml-4 text-gray-900;
}

.signup-form-standalone {
  @apply mt-6 mb-12;

  background-color: #f8f8ff;
}

figcaption {
  letter-spacing: -0.02em;
  font-weight: 300;
  font-style: normal;
  font-feature-settings: 'liga' on, 'lnum' on;
  font-size: 0.9rem;
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.68);
  letter-spacing: 0;
  position: relative;
  left: 0;
  width: 100%;
  top: 0;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.68);
  outline: 0;
  text-align: center;
}

.video-container {
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  position: relative;
}

.video-container iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
