@import 'highlight.js/styles/base16/dracula';

/* Dracula-specific override */
.hljs-comment {
  color: #8293c6;
}

code {
  @apply bg-gray-400 p-1 text-sm;

  background-color: ghostwhite;
}

pre {
  @apply mb-8;
  display: block;
  word-break: break-all;
  word-wrap: break-word;
}

pre code {
  background-color: #282a36;
  overflow: auto;
  font-weight: normal;
  color: #f8f8f2;
  display: block;
}

pre code,
.hljs {
  @apply leading-snug text-sm -mx-4 p-4;
}

@screen md {
  pre code,
  .hljs {
    @apply rounded -mx-6 p-6;
  }
}

@screen lg {
  pre code,
  .hljs {
    @apply text-base rounded-lg -mx-8 p-8;
  }
}
