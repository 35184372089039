.button {
  @apply text-white font-bold py-2 px-4 rounded text-xs;
}

.button:hover {
  @apply text-white;
}

.button-green {
  @apply bg-green-700;
}

.button-green:hover {
  @apply bg-green-600;
}

.button-blue {
  @apply bg-blue-600;
}

.button-blue:hover {
  @apply bg-blue-500;
}
