input:not([type]),
input[type='text'],
input[type='password'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='email'],
input[type='number'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
input[type='color'],
textarea {
  height: auto;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
}
